
import { defineComponent, reactive, ref } from 'vue';
import AppContent from '../bases/AppContent.vue';
import Button from '../bases/Button.vue';
import ModalMsg from '../bases/ModalMsg.vue';
import { GoogleAppsScript } from '../apis/googleScriptRun';
import '../assets/main.css';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  components: { AppContent, Button, ModalMsg },
  setup() {
    const store = useStore();

    const googleAppsScript = new GoogleAppsScript(store.state.token);
    const modal = ref(false);
    const complete = ref(false);
    const progressState = reactive({
      running: false,
      msg: '',
      max: 0,
      now: 0,
    });
    let resultURL = '';
    const target = store.state.parameter.ids[0];

    const aggregate = async () => {
      progressState.running = true;
      complete.value = false;
      modal.value = true;
      if (googleAppsScript === null) {
        return;
      }
      const params = store.state.parameter;
      const timer = setInterval(() => {
        getProgress();
      }, 1000);
      const res = await googleAppsScript.run('aggregateDocsTemplate', params.ids[0]);
      clearInterval(timer);
      if (res) {
        resultURL = res;
      }
      progressState.running = false;
      complete.value = true;
    };
    const getProgress = async () => {
      if (googleAppsScript === null) {
        return;
      }
      return await googleAppsScript
        .run('getUserCache', 'aggregateDoc')
        .then((res) => {
          return JSON.parse(res);
        })
        .then((res) => {
          progressState.msg = res.msg;
          progressState.max = res.max;
          progressState.now = res.now;
        });
    };
    const toNoParam = () => {
      router.push('/noParam');
    };
    const openResult = () => {
      window.open(resultURL, '_blank');
      modal.value = true;
    };

    return {
      aggregate,
      complete,
      progressState,
      modal,
      openResult,
      toNoParam,
      target,
    };
  },
});
