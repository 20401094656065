
import { defineComponent, reactive, ref, onMounted } from 'vue';
import AppContent from '@/bases/AppContent.vue';
import Button from '@/bases/Button.vue';
import { GoogleAppsScript } from '@/apis/googleScriptRun';
import '@/assets/main.css';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  components: { AppContent, Button },
  setup() {
    const store = useStore();
    onMounted(() => {
      if (
        store.state.token.token === null ||
        store.state.untill === false ||
        Date.now() > store.state.untll
      ) {
        router.push('/login');
      }
    });
    const googleAppsScript = new GoogleAppsScript(store.state.token);
    const modal = ref(false);
    const complete = ref(false);
    const isValid = ref(true);
    const progressState = reactive({
      running: false,
      msg: '',
      max: 0,
      now: 0,
    });
    let resultURL = '';

    const setParam = async () => {
      progressState.running = true;
      complete.value = false;
      modal.value = true;
      const para = document.getElementById('driveURL') as HTMLInputElement;
      const reg = new RegExp('https://drive.google.com/drive/folders/.*');
      if (!reg.test(para.value)) {
        modal.value = false;
        isValid.value = false;
        return;
      }
      const id = para.value.split('/')[5];
      store.commit('setParameter', JSON.stringify({ action: 'open', ids: [id] }));
      router.push('/execution');
    };
    const openResult = () => {
      console.log(resultURL);
      window.open(resultURL, '_blank');
      modal.value = true;
    };
    return {
      setParam,
      complete,
      progressState,
      modal,
      isValid,
      openResult,
    };
  },
});
