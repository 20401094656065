
import { defineComponent, onMounted } from 'vue';
import AppContent from '../bases/AppContent.vue';
import '../assets/main.css';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
declare var google: any;

export default defineComponent({
  components: { AppContent },
  setup() {
    const router = useRouter();
    const store = useStore();
    onMounted(() => {
      if (
        store.state.token.token !== null ||
        store.state.untill !== false ||
        Date.now() < store.state.untll
      ) {
        router.push('/noParam');
      }
    });
    const login = () => {
      // https://developers.google.com/identity/oauth2/web/reference/js-reference#TokenResponse
      const client = google.accounts.oauth2.initTokenClient({
        client_id: '165758524339-qb8aa9leqdi99gfktea4oi9q0v3al3je.apps.googleusercontent.com',
        scope:
          'https://www.googleapis.com/auth/drive \
          https://www.googleapis.com/auth/documents \
          https://www.googleapis.com/auth/spreadsheets \
          https://www.googleapis.com/auth/userinfo.email',
        callback: (response: any) => {
          const dt = Date.now();
          store.commit('setToken', {
            token: response.access_token,
            untill: dt + response.expires_in * 1000,
          });
          router.push('/noParam');
        },
      });
      client.requestAccessToken();
    };
    return {
      login,
    };
  },
});
