import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppContent = _resolveComponent("AppContent")!

  return (_openBlock(), _createBlock(_component_AppContent, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tables, (table) => {
        return (_openBlock(), _createElementBlock("button", {
          key: `${table.name}-button`,
          onClick: ($event: any) => (_ctx.show(table.name))
        }, _toDisplayString(table.name), 9, _hoisted_1))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tables, (table) => {
        return (_openBlock(), _createElementBlock("table", {
          key: table.name
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(table, (row) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row[0]
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (d) => {
                return (_openBlock(), _createElementBlock("td", { key: d }, _toDisplayString(d), 1))
              }), 128))
            ]))
          }), 128))
        ]))
      }), 128))
    ]),
    _: 1
  }))
}