
import { computed, defineComponent } from 'vue';
export default defineComponent({
  props: {
    color: {
      type: String,
      default: '#4285F4',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup(props, context) {
    const buttonType = computed(() => {
      return props.type === 'text' || props.type === 'fill' || props.type === 'line'
        ? props.type
        : 'text';
    });

    const styles = computed(() => {
      const rgbAry = hex2rgb(props.color);
      return {
        '--base-color': `rgba(${rgbAry[0]}, ${rgbAry[1]}, ${rgbAry[2]}, 1)`,
        '--whity-color': `rgba(${255 - rgbAry[2] / 20}, ${255 - rgbAry[1] / 20}, ${
          255 - rgbAry[0] / 20
        }, 1)`,
        '--pale-color': `rgba(${(255 - rgbAry[0]) / 2 + rgbAry[0]}, 
        ${(255 - rgbAry[1]) / 2 + rgbAry[1]}, ${(255 - rgbAry[2]) / 2 + rgbAry[2]}, 1)`,
        '--darken-color': `rgba(${rgbAry[0] - rgbAry[0] / 20}, ${rgbAry[1] - rgbAry[1] / 20}, ${
          rgbAry[2] - rgbAry[2] / 20
        }, 1)`,
        '--lighten-color': `rgba(${rgbAry[0] + rgbAry[0] / 20}, ${rgbAry[1] + rgbAry[1] / 20}, ${
          rgbAry[2] + rgbAry[2] / 20
        }, 1`,
      };
    });

    const onClick = (e: any) => {
      context.emit('click', e);
    };

    function hex2rgb(hex: string) {
      if (hex.slice(0, 1) == '#') hex = hex.slice(1);
      if (hex.length == 3)
        hex =
          hex.slice(0, 1) +
          hex.slice(0, 1) +
          hex.slice(1, 2) +
          hex.slice(1, 2) +
          hex.slice(2, 3) +
          hex.slice(2, 3);
      return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (str) {
        return parseInt(str, 16);
      });
    }
    return {
      onClick,
      styles,
      buttonType,
    };
  },
});
