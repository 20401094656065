
import router from '@/router';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const logoClick = () => {
      router.push('/');
    };
    return {
      logoClick,
    };
  },
});
