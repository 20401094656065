import { createStore, Store } from 'vuex';
import { InjectionKey } from 'vue';
import createPersistedState from 'vuex-persistedstate';

interface State {
  parameter: UrlParameter | null;
  token: {
    token: string | null;
    untill: number | false;
  };
}
export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  plugins: [
    createPersistedState({
      key: 'store',
      paths: ['token', 'parameter'],
      storage: window.sessionStorage,
    }),
  ],
  state: {
    parameter: null,
    token: {
      token: sessionStorage.getItem('store')
        ? JSON.parse(sessionStorage.getItem('store') as string).token.token
        : null,
      untill: sessionStorage.getItem('store')
        ? JSON.parse(sessionStorage.getItem('store') as string).token.untill
        : false,
    },
  },
  mutations: {
    setToken(
      state: State,
      token: {
        token: string;
        untill: number;
      }
    ) {
      state.token = token;
    },
    setParameter(state: State, param: string | null) {
      if (param !== null) {
        try {
          state.parameter = JSON.parse(param) as UrlParameter;
        } catch {
          state.parameter = null;
        }
      } else {
        state.parameter = param;
      }
    },
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getParameter(state) {
      return state.parameter;
    },
  },
  actions: {},
});
