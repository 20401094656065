
import { defineComponent, onMounted } from 'vue';
import AppContent from '../bases/AppContent.vue';
import '../assets/main.css';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { AppContent },
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      if (store.state.parameter) {
        router.push('/execution');
      }
    });
    return {};
  },
});
