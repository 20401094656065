
import { defineComponent } from 'vue';
import AppContent from '../bases/AppContent.vue';
import Button from '../bases/Button.vue';
import '../assets/main.css';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
declare var google: any;

export default defineComponent({
  components: { AppContent, Button },
  setup() {
    const router = useRouter();
    const store = useStore();
    const toApp = () => {
      router.push('/noParam');
    };
    const toTemplateSite = () => {
      window.open('https://template.kodomo-suite.jp/', '_blank');
    };
    return {
      toTemplateSite,
      toApp,
    };
  },
});
