import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["value", "max"]
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalMsg = _resolveComponent("ModalMsg")!
  const _component_AppContent = _resolveComponent("AppContent")!

  return (_openBlock(), _createBlock(_component_AppContent, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, "集計対象フォルダID:" + _toDisplayString(_ctx.target), 1),
      _createElementVNode("div", null, [
        _createVNode(_component_Button, {
          onClick: _ctx.toNoParam,
          type: "line"
        }, {
          default: _withCtx(() => [
            _createTextVNode("戻る")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_Button, {
          onClick: _ctx.aggregate,
          type: "fill"
        }, {
          default: _withCtx(() => [
            _createTextVNode("集計実行")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
        (_ctx.modal)
          ? (_openBlock(), _createBlock(_component_ModalMsg, {
              key: 0,
              title: "",
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal = false))
            }, {
              default: _withCtx(() => [
                (!_ctx.complete)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.progressState.msg), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, "集計が完了しました")),
                (!_ctx.complete)
                  ? (_openBlock(), _createElementBlock("progress", {
                      key: 2,
                      value: _ctx.progressState.now,
                      max: _ctx.progressState.max
                    }, _toDisplayString(_ctx.progressState.now) + "/" + _toDisplayString(_ctx.progressState.max), 9, _hoisted_3))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_Button, {
                        onClick: _ctx.openResult,
                        type: "fill"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("集計結果")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_Button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal = false)),
                        type: "line"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("とじる")
                        ]),
                        _: 1
                      })
                    ]))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    ]),
    _: 1
  }))
}