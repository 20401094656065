//
export { GoogleAppsScript };
const scriptId = '138OUbOgcV0Z263_yXN6zeHdr3uxi4urLuZRrWOFc1Eb5DXZdMaWPaUsc';
class GoogleAppsScript {
  private token;
  constructor(token: { token: string | null; untill: Date | false }) {
    this.token = token.token;
  }
  async run(functionName: string, ...args: [...any]): Promise<any> {
    if (!scriptId) {
      throw new ReferenceError('Script/Deploy ID is not defined');
    }
    return await fetch(`https://script.googleapis.com/v1/scripts/${scriptId}:run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({
        devMode: true,
        function: functionName,
        parameters: [...args],
      }),
    })
      .then((res) => {
        console.log(res);
        if (!res.ok) {
          throw new GASError(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        return new Promise((resolve, reject) => {
          if (res.done) {
            resolve(res.response.result);
          } else {
            console.error(`
              GAS Function Error on ${functionName}. \n
              detail: ${JSON.stringify(res)}`);
            reject(res);
          }
        });
      })
      .catch((e) => {
        console.error('google.apps.script error', e);
        return false;
      });
  }
}

class GASError extends Error {
  private user = 'unknown user';
  constructor(e?: string) {
    super(e);
    this.name = new.target.name;
  }
}
