
import { defineComponent, ref } from 'vue';
import AppContent from '../bases/AppContent.vue';
import '../assets/main.css';
export default defineComponent({
  components: { AppContent },
  setup() {
    const tables = ref();
    const show = (name: string) => {
      const table = document.getElementById(name) as HTMLTableElement;
      console.log(table);
    };
    return {
      tables,
      show,
    };
  },
});
